<template>
    <b-dropdown
        ref="languageSelector"
        right
        class="language-selector"
        :toggle-class="['language-selector__toggle-button', toggleClass]"
        :variant="variant"
        :size="size"
        menu-class="language-selector__menu"
    >
        <template v-slot:button-content>
            <img class="language-selector__flag" :src="currentLanguage.flag" :alt="currentLanguage.id" />
            <span>{{ currentLanguage.id }}</span>
        </template>
        <b-dropdown-item
            v-for="language in languages"
            :key="language.id"
            class="language-selector__menu-item"
            link-class="language-selector__menu-link"
            @click="changeLanguage(language.id)"
        >
            <img
                class="language-selector__flag language-selector__flag--list-item"
                :src="getFlag(language.id)"
                :alt="language.id"
            />
            {{ language.value }}
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Languages',
    props:{
        variant:{
            type:String,
            default:'',
        },
        size:{
            type:String,
            default:'',
        },
        toggleClass: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        ...mapGetters('ui', ['language', 'languages']),
        currentLanguage() {
            return {
                id: this.language,
                flag: this.getFlag(this.language),
            };
        }
    },
    mounted() {
        window.addEventListener('resize', this.setMaxMenuHeight)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setMaxMenuHeight)
    },
    methods: {
        ...mapActions('ui', ['setLanguage']),
        changeLanguage(lang) {
            this.setLanguage(lang);
        },
        setMaxMenuHeight() {
            const language_container = this.$refs.languageSelector.$el;
            const language_menu = this.$refs.languageSelector.$refs.menu;
            const max_height = window.innerHeight - language_container.getBoundingClientRect().bottom;

            language_menu.style.maxHeight = `${max_height - 10}px`;
            
        },
        getFlag(lang) {
            return `${process.env.VUE_APP_SHIPPING_ENVIA_URL}/flags/${lang.split('-').pop().toLowerCase()}.svg`;
        },
    }
}
</script>

<style lang="scss">
.language-selector {
    &__toggle-button {
        align-items: center;
        display: flex !important;
    }

    &__menu {
        background-color: $general-white !important;
        border: none !important;
        border-radius: 8px !important;
        box-shadow: $elevation-500;
        height: 450px;
        overflow-y: auto;
        padding: 8px !important;
    }

    &__menu-item {
        padding: 0 !important;
    }

    &__menu-link {
        border-radius: 4px;
        color: $general-black !important;
        font-size: 0.875rem;
        padding: 8px !important;

        &:hover {
            background-color: $accounts-secondary-100 !important;
        }
    }

    &__flag {
        border-radius: 4px;
        height: 30px;
        width: 30px;
        margin-right: 8px;

        &--list-item {
            height: 24px;
            width: 24px;
        }
    }
}
</style>
