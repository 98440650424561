<template>
    <div class="actions" v-if="itsAPopup || redirectUrl">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <popup-actions v-if="itsAPopup" />
                <b-button
                    v-if="!itsAPopup && redirectUrl"
                    variant="primary"
                    class="w-100"
                    @click="redirect">
                    <span v-text="$t('Continue')"></span>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script type='text/javascript'>
import PopupActions from './popup-actions.vue';

export default {
    name: 'Actions',
    components: { PopupActions },
    props: {
        redirectUrl: {
            type: String,
            default: null,
        },
    },  
    data() {
        return { window: window }
    },
    computed: {
        itsAPopup: function () {
            return this.window.opener
                && this.window.opener !== this.window
                && !this.window.menubar.visible;
        }
    },
    methods: {
        redirect() {
            window.location.href = this.redirectUrl;
        }
    },
};
</script>

<style lang='scss' scoped>
.actions {
    width: 100%;
    padding: 30px;
}
</style>
