export default {
    data() {
        return { window: window }
    },
    computed: {
        itsAPopUp: function () {
            return this.window.opener
                && this.window.opener !== this.window
                && !this.window.menubar.visible;
        }
    },
};
