<!-- Privacy & Terms Conditions -->
<template>
    <!-- container -->
    <div v-if="!itsAPopUp" class="row justify-content-center">
        <div class="col-auto">
            <p>
                <a href='https://ecartpay.com/privacy-policy' class="text-muted f-w-500" :target="itsAPopUp ? '_blank' : '_self'">
                    {{ $t('Privacy notice') }}
                </a>
            </p>
        </div>
        <div class="col-auto">
            <p>
                <a href="https://ecartpay.com/terms-and-conditions" class="text-muted f-w-500" :target="itsAPopUp ? '_blank' : '_self'">
                    {{ $t('Terms and conditions') }}
                </a>
            </p>
        </div>
    </div>
    <!-- container -->
</template>

<script>
import popupMixin from '@/plugins/mixin/pop-up.mixin';

export default {
    name: 'PrivacyConditions',
    mixins: [popupMixin],
};
</script>