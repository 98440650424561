<template>
    <div class="popup-actions">
        <b-button
            variant="primary"
            class="w-100"
            onclick="self.close()">
            <span v-text="$t('general.button.close')"></span>
        </b-button>
    </div>
</template>

<script type='text/javascript'>
export default {
    name: 'PopupActions',
};
</script>

<style lang='scss'>
.popup-actions {
    width: 100%;
}
</style>
