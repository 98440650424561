<template>
    <page-loader v-if="this.$route.params.status === 'loading'" />
    <layout card-class="card-w-lg" v-else>
        <div class="status-page">
            <div class="status-page-message" v-if="status">
                <div class="icon-container" :class="status.class">
                    <span :class="`fas ${status.icon}`" />
                </div>
                <h2 v-if="title">{{ title }}</h2>
                <p>
                    {{ this.$t(message.key, message.parameters) }}
                </p>
            </div>
            <actions :redirect-url="redirectUrl" />
        </div>
    </layout>
</template>

<script>
import Layout from '@/components/layout/layout.vue';
import PageLoader from '@/components/page_loader.vue';
import Actions from './components/actions.vue';

export default {
    name: 'Status',
    components: {
        Layout,
        PageLoader,
        Actions,
    },
    data() {
        return {
            status: null,
            title: null,
            message: null,
            redirectUrl: null,
            statusesMap: {
                success: {
                    class: 'success',
                    icon: 'fa-check',
                },
                error: {
                    class: 'error',
                    icon: 'fa-times',
                },
                warning: {
                    class: 'warning',
                    icon: 'fa-exclamation',
                },
            },
            messagesMap: {
                requiredParameter: 'The request is required to have the {parameter} parameter.',
                nonExistentService: 'Sorry, there is no service with the name {service}.',
                pending: 'Your application is being reviewed by the provider. This can take between 1 to 10 minutes.',
                completed: 'Your application was approved. Now you can use the system.',
                declined: 'Your application was rejected by the provider.',
                expired: 'Your session on this link was ended, please retry creating a new link to continue with the verification process.',
                formSentSuccessfully: 'Form sent successfully'
            },
        };
    },
    methods: {
        loadData() {
            const { _v } = this.$route.query;

            if (!_v) {
                this.status = this.statusesMap.error;
                this.message = {
                    key: this.messagesMap.requiredParameter,
                    parameters: { parameter: '_v' },
                };

                return;
            }

            const decodedServiceData = Buffer.from(_v, 'base64');
            const { title, message, redirect_url } = JSON.parse(decodedServiceData.toString());

            this.status = this.statusesMap[this.$route.params.status];
            this.title = title;
            this.redirectUrl = redirect_url || null;
            this.message = {
                key: this.messagesMap?.[message],
                parameters: null,
            };
        },
    },
    mounted() {
        this.loadData();
    }
}
</script>

<style lang='scss'>
.status-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;

    .status-page-message {
        padding: 60px 30px;
        width: 700px;
        max-width: 100%;
        text-align: center;

        .icon-container {
            height: 90px;
            width: 90px;
            border: 3px solid#eeeeee;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;

            &.success {
                border-color: #d4f4d8;
                color: #3CAA91;
            }

            &.error {
                border-color: #f6e1e3;
                color: #da4b5e;
            }

            &.warning {
                border-color: #f2dea2;
                color: #bc8a2a;
            }
        }

        h2 {
            color: #575757;
            margin-bottom: 15px;
        }

        p {
            font-size: 1.1rem;
            color: #797979;
        }
    }
}
</style>
