<template>
    <div class="layout">
        <div class="layout-content">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-12 layout-content-card">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-auto">
                                    <div class="row justify-content-end">
                                        <div class="col-auto">
                                            <Languages variant="link" toggle-class="layout__language-selector" />
                                        </div>
                                        <div class="d-none">
                                            <PrivacyConditions />
                                        </div>
                                    </div>
                                    <b-overlay
                                        rounded="sm"
                                        class="card layout-card"
                                        :class="cardClass"
                                        :style="cardStyle"
                                        :show="cardLoading">
                                        <slot></slot>
                                    </b-overlay>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PrivacyConditions from '@/pages/accounts/privacy_conditions/PrivacyConditions.vue';
import Languages from "@/components/Languages";

export default {
    name: 'Layout',
    components: {
        PrivacyConditions,
        Languages,
    },
    props: {
        cardClass: {
            type: String,
            default: 'card-w-xl',
        },
        cardStyle: {
            type: [Array, Object],
            default: null,
        },
        cardLoading: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang='scss'>
.layout {
    background-color: black;
    padding-top: 25vh;

    &__language-selector {
        color: #ffffff !important;
        font-weight: 500 !important;
        text-decoration: none !important;
        margin-bottom: 15px;
    }

    .layout-content {
        min-height: 75vh;
        background-color: white;

        @media only screen and (max-width: 767px) {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        .layout-content-card {
            transform: translateY(-15vh);

            @media only screen and (max-width: 767px) {
                transform: translateY(-15vh);
            }

            .card {
                max-width: 100%;

                &.layout-card {
                    min-height: 500px;
                }

                @media only screen and (max-width: 767px) {
                    width: auto;
                }

                &-w-sm {
                    width: 300px;
                }
    
                &-w-md {
                    width: 500px;
                }
    
                &-w-lg {
                    width: 800px;
                }
    
                &-w-xl {
                    width: 1140px;
                }
            }
        }
    }
}
</style>
